import React from "react";

import GenericError from "./GenericError";

import { ErrorHandlers } from "./types";

const HttpError: React.FC<ErrorHandlers.IHttpErrorProps> = ({
  name,
  message,
  axiosError,
  imageUrl,
}): JSX.Element => {
  return (
    <GenericError
      imageUrl={imageUrl}
      heading={name || `Error ${axiosError?.response?.status}`}
      description={message || axiosError?.response?.data?.detail}
    />
  );
};

export default HttpError;
