import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const ListItemSection: React.FC<NSLayout.ListItemSectionProps> = ({
  listItemProps,
  listItemIconProps,
  listItemTextProps,
  icon,
  content,
}) => {
  return (
    <ListItem {...listItemProps}>
      <ListItemIcon {...listItemIconProps}>{icon}</ListItemIcon>
      <ListItemText {...listItemTextProps}>{content}</ListItemText>
    </ListItem>
  );
};

export default ListItemSection;
