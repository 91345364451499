import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { neutral } from "../../../common/config/colors";

const UserEnrollData: React.FC<{
  message: React.ReactNode;
  buttonText: string;
  id: string;
  boxProps?: BoxProps;
}> = ({ buttonText, id, message, boxProps }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={neutral["A500"]}
      width={{ xs: 298, md: 400 }}
      rowGap={{ xs: 6, md: 8 }}
      sx={{
        borderRadius: "32px",
        p: 7.5,
      }}
      {...(boxProps || {})}
    >
      {message}
      <Box>
        <Button
          href={`#${id}`}
          endIcon={<ArrowDownwardIcon/>}
          variant="contained"
          color="primary"
          sx={{
            fontSize: 14,
            fontWeight: 600,
            py: 2.25,
          }}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default UserEnrollData;
