import React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { NSLayout } from "../../types";

const Heading: React.FC<NSLayout.HeadingProps> = ({
  headingProps,
  content,
}) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h1"
      color={theme.palette.primary.contrastText}
      sx={{
        position: "relative",
        fontSize: { xs: 32, md: 40 },
        fontWeight: 700,
        mb: { xs: 4, md: 6 },
        textAlign: { xs: "center", lg: "left" },
        lineHeight: 1.3,
        "&::before": {
          content: `""`,
          position: "absolute",
          display: { xs: "none", lg: "block" },
          height: "100%",
          width: 10,
          background: theme.palette.secondary.main,
          left: -50,
        },
        ...(headingProps?.sx || {}),
      }}
    >
      {content}
    </Typography>
  );
};

export default Heading;
