import React from "react";
import HttpError from "../error-handlers/HttpError";
import GenericListSkeleton from "../skeletons/GenericListSkeleton";

import { IGenericResponseHandlerProps } from "./types";

const GenericResponseHandler: React.FC<IGenericResponseHandlerProps> = ({
  status,
  skeleton = <GenericListSkeleton items={3} />,
  children,
  errorPlaceholder,
  error,
}) => {
  switch (status) {
    case "idle":
      return <></>;
    case "pending":
      return skeleton;
    case "rejected":
      return errorPlaceholder || <HttpError axiosError={error} />;
    case "resolved":
      return <>{children}</>;
    default:
      throw new Error(`${status} is an invalid status.`);
  }
};

export default GenericResponseHandler;
