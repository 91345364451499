import React from "react";
import Image from "next/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";

import Section from "../../../common/components/layout/utils/Section";

import {
  LIST_PAGE_DESKTOP_IMAGE,
  LIST_PAGE_MOBILE_LOGOS,
} from "../../../live-sessions/utils/constants";
import { dphiBlue, neutral } from "../../../common/config/colors";

const Experts: React.FC<{ title: string }> = ({ title }) => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Section
      boxProps={{
        bgcolor: dphiBlue["A200"],
        sx: {
          py: 10,
          [theme.breakpoints.down("md")]: {
            height: "100%",
            py: 8,
          },
        },
      }}
      containerProps={{
        sx: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          rowGap: 8,
        },
      }}
    >
      <>
        <Typography variant="h5" color={neutral["A500"]} align="center">
          {title}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Image
            src={isDownMd ? LIST_PAGE_MOBILE_LOGOS : LIST_PAGE_DESKTOP_IMAGE}
            alt="logos"
            width={isDownMd ? "132px" : "732"}
            height={isDownMd ? "272px" : "50px"}
          />
        </Box>
      </>
    </Section>
  );
};

export default Experts;
