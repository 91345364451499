import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";

import GenericResponseHandler from "@packages/common/src/components/others/GenericResponseHandler";
import GenericCardListSkeleton from "@packages/common/src/components/skeletons/GenericCardListSkeleton";
import useAsync, {
  IUseAsyncReturnProps,
} from "@packages/common/src/custom-hooks/useAsync";

import UserEnrollData from "../../../courses/components/list/UserEnrollData";
import { neutral, primary } from "../../../common/config/colors";

const UserCoursesData = () => {
  const {
    data: bootcampsData,
    run,
    status,
    error,
  }: IUseAsyncReturnProps<NSBootcamps.IBootcampsHeaderSectionProps> = useAsync();

  useEffect(() => {
    const fetchPaths = async () => {
      const bootcampsService = new (
        await import("../../service/bootcampsService")
      ).default();

      run(bootcampsService.bootcampsEnrolled());
    };
    fetchPaths();
  }, [run]);

  return (
    <>
      <GenericResponseHandler
        status={status}
        error={error}
        skeleton={
          <GenericCardListSkeleton
            items={1}
            boxProps={{
              width: { xs: 298, md: 400 },
              height: 200,
              bgcolor: neutral[200],
              borderRadius: "32px",
            }}
            gridItemProps={{ md: 12, lg: 12 }}
          />
        }
      >
        <UserEnrollData
          message={
            <Typography
              variant="h6"
              color={neutral["900"]}
              sx={{
                fontWeight: 500,
                fontSize: 18,
              }}
            >
              {bootcampsData?.number_of_bootcamps_enrolled_in === 0 ? (
                "Start your learning journey by enrolling in bootcamps"
              ) : (
                <>
                  {`Hey ${bootcampsData?.username}, you have `}
                  <Typography
                    variant="h6"
                    component="span"
                    color={primary["A500"]}
                    sx={{
                      fontWeight: 700,
                      py: 4,
                      fontSize: 18,
                    }}
                  >
                    {`enrolled in ${bootcampsData?.number_of_bootcamps_enrolled_in} bootcamps `}
                  </Typography>
                  {"so far. Keep it up! 🔥"}
                </>
              )}
            </Typography>
          }
          buttonText="Explore Bootcamps"
          id="exploreBootcamps"
        />
      </GenericResponseHandler>
    </>
  );
};

export default UserCoursesData;
