import React from "react";
import Error from "next/error";
import onError, { IOnError } from "./utils/onError";

/**
 * Use this component in the page component(inside pages directory).
 * */
const PageError: React.FC<{
  error: IOnError;
}> = ({ error }) => {
  return (
    <Error
      statusCode={error.status}
      title={(error.data as { detail: string })?.detail || error.message}
    />
  );
};

export default PageError;
export { onError };
export type { IOnError };
