import { SERVER } from "../../common/config/utils";

export const LIST_HEAD_SECTION_IMAGE =
  "/live-sessions/live-sessions-head-section.svg";
export const LIST_PAGE_DESKTOP_IMAGE =
  "/live-sessions/live-sesion-list-page-logos.svg";
export const LIST_PAGE_MOBILE_LOGOS =
  "/live-sessions/live-sessions-list-page-mobile-logos.png";

export const LIVE_SESSIONS_OG_IMAGE = `${SERVER}/live-sessions/og-image.png`;
export const CREDIT_CARD_IMAGE = "/live-sessions/credit-card-icon.svg";

export const LIVE_SESSIONS_URL = "/live-sessions/";
export const LIVE_SESSIONS_ENROLLED = "/live-sessions/landing-page/main/";
