import { BoxProps, GridProps, useTheme } from "@mui/material";
import GenericListSkeleton from "./GenericListSkeleton";

const GenericCardListSkeleton: React.FC<{
  items?: number;
  gridItemProps?: GridProps;
  boxProps: BoxProps;
  gridProps?: GridProps;
}> = ({ items = 3, boxProps, gridItemProps, gridProps }) => {
  const theme = useTheme();

  return (
    <GenericListSkeleton
      items={items}
      gridProps={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        rowGap: 12,
        alignItems: "center",
        ...(gridProps || {}),
        sx: {
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
          ...(gridProps?.sx || {}),
        },
      }}
      gridItemProps={{
        sx: { display: "grid", placeItems: "center" },
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        ...(gridItemProps || {}),
      }}
      boxProps={boxProps}
    />
  );
};

export default GenericCardListSkeleton;
