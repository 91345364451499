import React from "react";
import List from "@mui/material/List";
import { useTheme } from "@mui/material";

const ListSection: React.FC<NSLayout.ListSectionProps> = ({
  listProps,
  children,
}) => {
  const theme = useTheme();

  return (
    <List
      sx={{
        mb: 8,
        "& .MuiListItem-root": {
          gap: 4.5,
          pl: 0,
          py: 1.5,
        },
        "& .MuiListItemIcon-root": {
          minWidth: "auto",
          color: "#F9DB91",
          width: 22,
        },
        "& .MuiTypography-root": {
          color: theme.palette.primary.contrastText,
          fontWeight: 500,
          fontSize: { xs: 16, md: 18 },
        },
        ...(listProps?.sx || {}),
      }}
    >
      {children}
    </List>
  );
};

export default ListSection;
