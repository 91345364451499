export const MENTORSHIP_IMAGE = "/bootcamps/mentorship.svg";
export const COMMUNITY_NETWORKING_IMAGE = "/bootcamps/community-networking.svg";
export const CERTIFICATE_OF_ACCOMPLISHMENT =
  "/bootcamps/certificate-of-accomplishment.svg";

export const DETAIL_PAGE_CERTIFICATE_IMAGE =
  "/bootcamps/certificate-detail-page.png";

export const DETAIL_PAGE_LOGOS = "/bootcamps/detail-page-logos.svg";
export const LIST_PAGE_LOGOS = "/bootcamps/list-page-logos.svg";
export const BOOTCAMPS_URL = "/bootcamps/";

export const BOOTCAMPS_LIST_PAGE_HEADSECTION_IMAGE = "/bootcamps/Rocket.svg";

export const FACEBOOK = "/bootcamps/Facebook.svg";
export const LINKEDIN = "/bootcamps/Linkedin.svg";
export const INSTAGRAM = "/bootcamps/Instagram.svg";
export const EMAIL = "/bootcamps/E-mail.svg";
export const TWITTER = "/bootcamps/Twitter.svg";
export const COPYLINK = "/bootcamps/Copy-link.svg";
export const COPY = "/bootcamps/Copy.svg";

export const BANNER = "/bootcamps/Banner_1.svg";
export const DOTS = "/bootcamps/Dots_1.svg";

export const COURSE_PAGE_LINKEDIN_URL = `https://www.linkedin.com/shareArticle?mini=true&url=${
  typeof window !== "undefined" ? window.location.href : "https://aiplanet.com/"
}?utm_source=course_page&utm_medium=linkedin&utm_campaign=rp`;
export const COURSE_PAGE_FACEBOOK_URL = `https://www.facebook.com/sharer/sharer.php?u=${
  typeof window !== "undefined" ? window.location.href : "https://aiplanet.com/"
}?utm_source=course_page&utm_medium=facebook&utm_campaign=rp`;

export const BOOTCAMPS_ENROLLED = "/bootcamps/landing-page/main/";
