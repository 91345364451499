import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery } from "@mui/material";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PublicIcon from "@mui/icons-material/Public";
import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";

import HeroSection from "../../../common/components/layout/hero-section/HeroSection";
import Heading from "../../../common/components/layout/common/heading/Heading";
import ListSection from "../../../common/components/list/ListSection";
import ListItemSection from "../../../common/components/list/ListItemSection";
import authService from "../../../common/service/authService";
import SignUpForm from "../../../learning-paths/components/common/SignUpForm";
import UserBootcampsData from "./UserBootcampsData";
import { BOOTCAMPS_URL } from "../../utils/constants";

const HeadSection = () => {
  const theme = useTheme();
  const isUpLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <HeroSection
      boxProps={{
        py: { xs: 12, md: 24 },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: { sm: "center", lg: "space-between" },
          alignItems: "center",
          pl: { sm: 0, lg: 15 },
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: 450, md: 540 },
            display: "inline-flex",
            flexDirection: "column",
            alignItems: { xs: "center", lg: "flex-start" },
            mr: { xs: 0, lg: 8 },
          }}
        >
          <Heading
            headingProps={{ sx: { maxWidth: 500 } }}
            content="Data Science  Community Bootcamps"
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: 16, md: 18 },
              textAlign: { xs: "center", lg: "left" },
              color: theme.palette.primary.contrastText,
              mb: { xs: 8, lg: 6 },
              letterSpacing: 0.5,
            }}
          >
            A place where you can learn together and grow together as a
            community.
          </Typography>
          <ListSection
            listProps={{
              sx: {
                display: { xs: "none", lg: "block" },
                mb: 0,
                py: authService.isAuthenticated && isUpLg ? 0 : 2,
              },
            }}
          >
            <ListItemSection
              icon={<MoneyOffIcon />}
              content="Learn at the cost of $0"
            />
            <ListItemSection
              icon={<PublicIcon />}
              content="Work on real-world projects"
            />
            <ListItemSection
              icon={<ChromeReaderModeOutlinedIcon />}
              content="Bootcamp materials for life"
            />
          </ListSection>
        </Box>
        <Box>
          {authService.isAuthenticated ? (
            <UserBootcampsData />
          ) : (
            <SignUpForm redirect_url={BOOTCAMPS_URL} />
          )}
        </Box>
      </Box>
    </HeroSection>
  );
};

export default HeadSection;
