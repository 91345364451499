import React from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { useTheme, useMediaQuery } from "@mui/material";

import GenericError from "@packages/common/src/components/error-handlers/GenericError";
import RootPageLoader from "@packages/common/src/components/skeletons/RootPageLoader";

import HeadSection from "../../src/bootcamps/components/list/HeadSection";
import Experts from "../../src/bootcamps/components/list/Experts";
import { SERVER } from "../../src/common/config/utils";
import { SITE_NAME } from "../../src/common/config/seo";
import PageError, {
  onError,
} from "../../src/common/components/error-handlers/PageError";

const Cohort = dynamic(
  () => import("../../src/bootcamps/components/list/cohort/Cohort"),
  {
    loading: () => <RootPageLoader />,
  }
);
const Speakers = dynamic(
  () => import("../../src/bootcamps/components/list/Speakers"),
  { ssr: false }
);
const WhyYouShouldEnroll = dynamic(
  () =>
    import(
      "../../src/bootcamps/components/list/bootcamp-enroll/WhyYouShouldEnroll"
    )
);
const DPhiTestimonials = dynamic(
  () =>
    import(
      "../../src/wall-of-love/components/dphi-testimonials/DPhiTestimonials"
    )
);
const Banner = dynamic(
  () => import("../../src/bootcamps/components/list/banner/Banner"),
  { ssr: false }
);
const Faqs = dynamic(() => import("../../src/common/components/others/Faqs"));

export default function ({
  cohorts,
  error,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  if (error) {
    return <PageError error={error} />;
  }

  return (
    <>
      <Head>
        <title>Data Science Community Bootcamps | {SITE_NAME}</title>
        <meta
          name="description"
          content="Learn Data Science for free through AI Planet's community bootcamps and get access to resources including expert sessions, real world projects, community learning and more..."
        />
        <link rel="canonical" href={`${SERVER}/bootcamps`} />
        <meta property="og:url" content={`${SERVER}/bootcamps`} />
        <meta
          property="og:title"
          content={`Data Science Community Bootcamps | ${SITE_NAME}`}
        />
        <meta
          property="og:description"
          content="Learn Data Science for free through AI Planet's community bootcamps and get access to resources including expert sessions, real world projects, community learning and more..."
        />
        <meta
          property="og:image"
          content={`${SERVER}/bootcamps/og-image.png`}
        />
        {/* twitter tags */}
        <meta property="twitter:url" content={`${SERVER}/bootcamps`} />
        <meta
          name="twitter:title"
          content={`Data Science Community Bootcamps | ${SITE_NAME}`}
        />
        <meta
          name="twitter:description"
          content="Learn Data Science for free through AI Planet's community bootcamps and get access to resources including expert sessions, real world projects, community learning and more..."
        />
        <meta
          name="twitter:image"
          content={`${SERVER}/bootcamps/og-image.png`}
        />
      </Head>
      <HeadSection />
      {isMdDown && <Experts title="Attended By Learners At" />}
      {cohorts?.error ? (
        <GenericError
          heading="Error"
          description={cohorts?.error || "something went wrong"}
        />
      ) : (
        <Cohort cohorts={cohorts.cohorts} />
      )}
      <Speakers />
      <WhyYouShouldEnroll />
      <Banner />
      <DPhiTestimonials />
      {cohorts?.faqs && <Faqs faqs={cohorts.faqs} />}
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  try {
    const service = new (
      await import("./../../src/common/service/baseServerSideService")
    ).default(req);
    const { data: cohorts } = await service.get(
      `${SERVER}/api/bootcamp-cohorts/`
    );

    if (!cohorts) {
      return {
        notFound: true,
      };
    }
    return {
      props: {
        cohorts,
      },
    };
  } catch (error) {
    return onError(error);
  }
};
